import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import './IdRegistrationPage.css';

import { Beyond, Bouwdeel, Installation, IdRegistration, Project, Status, Vendor, Vendortype } from '../../common/types';
import { verdiepingen } from '../../common/consts';
import { VendorComponent } from './components/VendorComponent';
import { IPComponent } from './components/IPComponent';
import { DOIComponent } from './components/DOIComponent';
import { ProjectComponent } from './components/ProjectComponent';
import { BouwdeelComponent } from './components/BouwdeelComponent';
import { ProcessComponent } from './components/ProcessComponent';
import { ToggleComponent } from './components/ToggleComponent';
import { BeyondComponent } from './components/BeyondComponent';
import { StatusComponent } from './components/StatusComponent';
import { NetworkComponent } from './components/NetworkComponent';
import { DeleteComponent } from '../../components/DeleteComponent';
import { GatewayComponent } from './components/GatewayComponent';
import { LocationComponent } from './components/LocationComponent';
import { SaveComponent } from './components/SaveComponent';

export type IdRegistrationPageProps = {
  fetchRegistrations: () => void;
  cancel: () => void;
  registrations: IdRegistration[];
  opdrachtgever: string;
  registrationId?: string;
};

export enum BatchOptions {
  falseText = 'Enkel',
  trueText = 'Batch',
}

const emptyRegistration = (opdrachtgever: string): IdRegistration => ({
  location: '',
  gateway: '',
  installation: '',
  floor: '',
  doi: -1,
  mac_address: '',
  to: '',
  process: '',
  notes: '',
  ip_address: '',
  controller_name: '',
  network_number: -1,
  status: Status.REQUEST,
  opdrachtgever_id: opdrachtgever
});

export const IdRegistrationPage = (props: IdRegistrationPageProps) => {
  const [newRegistration, setNewRegistration] = useState<IdRegistration>(emptyRegistration(props.opdrachtgever));
  const [isBatch, setIsBatch] = useState(false);
  const [batchAmount, setBatchAmount] = useState(1);
  const [inferedControllerName, setInferedControllerName] = useState<string>('');
  const [isOld, setIsOld] = useState(false);

  useEffect(() => {
    setIsOld(newRegistration.status === Status.MIGRATED);
  }, [newRegistration.status]);

  useEffect(() => {
    if (!props.registrationId) {
      setNewRegistration(emptyRegistration(props.opdrachtgever));
    }
    setIsBatch(false);
  }, [props.registrationId]);

  useEffect(() => {
    setInferedControllerName(
      (newRegistration.bouwdeel?.name || newRegistration.project?.bouwdeel?.name || '')
      + (newRegistration.location || '')
      + (newRegistration.doi > 0 ? 'D' + newRegistration.doi : '')
    );
  }, [newRegistration.project, newRegistration.bouwdeel, newRegistration.location, newRegistration.doi]);

  useEffect(() => {
    if (props.registrationId) {
      const reg = props.registrations.filter(reg => reg.id === props.registrationId)[0];
      if (reg) {
        setNewRegistration(prevState => ({
          ...prevState,
          ...reg,
        }));
      }
    }
  }, [props.registrationId, props.registrations]);

  const refresh = () => {
    if(!newRegistration.id) {
      setNewRegistration(emptyRegistration(props.opdrachtgever));
    }
    props.fetchRegistrations();
    props.cancel();
  }

  const updateRegistrationByName = useCallback(
    (name: string, value: string | number | Project | Bouwdeel | Beyond | Vendor | Vendortype | undefined) => {
      setNewRegistration(prevState => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setNewRegistration]
  );

  const updateRegistration = useCallback(
    (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
      updateRegistrationByName(event.target.name, event.target.value);
    },
    [updateRegistrationByName]
  );

  const setBatch = (text: string) => {
    setIsBatch(text === BatchOptions.trueText);
    updateRegistrationByName('installation', Installation.NAREGELING);
  };

  return (
    <div className="chair-item registration">
      <h2>
        {newRegistration.id
          ? (newRegistration.controller_name || inferedControllerName)
          : 'Nieuw'
        }
      </h2>
      {isOld &&
        <div className="migrated">
          Let op, dit is een gemigreerde registratie! Hierdoor zijn niet alle velden beschikbaar.
        </div>}
      {props.registrationId && (
        <div className="property">
          <button onClick={() => props.cancel()}>Cancel</button>
        </div>
      )}
      {!props.registrationId && (
        <div className="property">
          <label></label>
          <ToggleComponent falseText={BatchOptions.falseText} trueText={BatchOptions.trueText} feedback={isBatch} toggle={setBatch} />
        </div>
      )}
      {isBatch && (
        <div className="property">
          <label>Aantal</label>
          <input data-testid="input-amount" type="text" name="batch-amount" value={batchAmount} onChange={event => setBatchAmount(Number(event.target.value))} />
        </div>
      )}
      {!isBatch &&
        <div className="property">
          <label>Controller naam</label>
          <input data-testid="input-controller-name" type="text" name="controller_name" value={newRegistration.controller_name || inferedControllerName} onChange={updateRegistration} placeholder='Wordt autogegenereerd' />
        </div>
      }
      <div className="property">
        <label>Installatie</label>
        <select data-testid="select-installation" disabled={isBatch} name="installation" value={newRegistration.installation} onChange={updateRegistration} className={newRegistration.installation ? '' : 'required'}>
          <option value="">Selecteer installatie</option>
          {Object.entries(Installation).map(([, value], index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </select>
      </div>
      {!isOld && <ProjectComponent project={newRegistration.project} opdrachtgever={props.opdrachtgever} updateRegistration={updateRegistrationByName} />}
      {!isOld && 
        <BouwdeelComponent
          registration={newRegistration}
          opdrachtgever={props.opdrachtgever}
          updateRegistration={updateRegistrationByName}
        />
      }
      <div className="property">
        <label>Verdieping</label>
        <select name="floor" value={newRegistration.floor} onChange={updateRegistration} className={newRegistration.floor ? '' : 'required'}>
          <option value="">Selecteer verdieping</option>
          {verdiepingen.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      {!isBatch && (
        <LocationComponent registration={newRegistration} updateRegistration={updateRegistrationByName} />
      )}
      {!isBatch && (
        <DOIComponent
          curRegistration={newRegistration}
          registrations={props.registrations}
          updateRegistration={updateRegistrationByName}
        />
      )}
      {!isOld &&
        <NetworkComponent registration={newRegistration} updateRegistration={updateRegistrationByName} />
      }
      <BeyondComponent registration={newRegistration} registrations={props.registrations} updateRegistration={updateRegistrationByName} />
      <VendorComponent updateRegistration={updateRegistrationByName} registration={newRegistration} registrations={props.registrations} />
      {!isOld &&
        <GatewayComponent registration={newRegistration} updateRegistration={updateRegistrationByName} bouwdeel={newRegistration.bouwdeel || newRegistration.project?.bouwdeel} />
      }
      {!isBatch && (
        <IPComponent
          updateRegistration={updateRegistrationByName}
          registration={newRegistration}
          registrations={props.registrations}
          selectedReg={props.registrationId}
        />
      )}
      {!isBatch && (
        <div className="property">
          <label>MAC-adres</label>
          <input data-testid="input-mac" type="string" name="mac_address" value={newRegistration.mac_address} onChange={updateRegistration} />
        </div>
      )}
      {!isBatch && (
        <div className="property">
          <label>Technical Outlet</label>
          <input data-testid="input-to" type="string" name="to" value={newRegistration.to} onChange={updateRegistration} />
        </div>
      )}
      {!isOld &&
        <ProcessComponent registration={newRegistration} updateRegistration={updateRegistrationByName} />
      }
      {!isBatch && (
        <div className="property">
          <label>Notities</label>
          <textarea data-testid="textarea-notes" name="notes" value={newRegistration.notes} onChange={updateRegistration} />
        </div>
      )}
      <div className="property">
        <label>Status</label>
        <StatusComponent registration={newRegistration} updateRegistration={updateRegistrationByName} />
        <div className="filler"></div>
      </div>
      <div className="chair-item-header">
        <SaveComponent isBatch={isBatch} isOld={isOld} batchAmount={batchAmount} registration={newRegistration} registrations={props.registrations} refresh={refresh} />
        {props.registrationId && <DeleteComponent path={`/api/id-registration/${props.registrationId}`} name='Id-registratie' refresh={refresh} />}
      </div>
    </div>
  );
};
