import React, { useEffect } from 'react';

import './StatusComponent.css';
import { IdRegistration } from '../../../common/types';

export type NetworkProps = {
  registration: IdRegistration;
  updateRegistration: (name: string, value: number) => void;
};

export const NetworkComponent = ({ registration, updateRegistration }: NetworkProps) => {
  useEffect(() => {
    if (registration.parent_beyond) {
      updateRegistration('network_number', registration.parent_beyond.network_number);
    }
  }, [registration.parent_beyond]);

  return (
    <div className="property">
      <label htmlFor="networknumber">Netwerknummer</label>
      <input
        type="number"
        id="networknumber"
        name="networknumber"
        value={registration.network_number || ''}
        disabled={!!registration.parent_beyond}
        className={registration.network_number === -1 ? 'error' : ''}
        onChange={e => updateRegistration('network_number', Number(e.target.value))}></input>
    </div>
  );
};