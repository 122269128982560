import React, { ChangeEvent, useEffect, useState } from 'react';

import './OpdrachtgeverPage.css';

import { Bouwdeel, Opdrachtgever, Project } from '../../common/types';
import { RES_TYPES, useFetch } from '../../hooks/useFetch';
import { BouwdeelPage } from './BouwdeelPage';
import { ProjectPage } from './ProjectPage';
import { useNavigate, useParams } from 'react-router-dom';
import { FaSave } from 'react-icons/fa';
import { useCrumbOptionsContext } from '../../crumbOptionsProvider';
import { DeleteComponent } from '../../components/DeleteComponent';

const createEmptyOpdrachtgever = () => {
  return {
    name: '',
    bouwdelen: [],
  } as Opdrachtgever;
};

export const OpdrachtgeverPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { updateSelectedOption } = useCrumbOptionsContext();
  const {
    data: projecten,
    fetchData: fetchProjecten,
    setUrl: setProjectenUrl,
  } = useFetch<Project[]>('', {
    defaultValue: [],
  });
  const {
    data: opdrachtgever,
    fetchData: fetchOpdrachtgever,
    setUrl: setOpdrachtgeverUrl,
  } = useFetch<Opdrachtgever>('', {
    defaultValue: createEmptyOpdrachtgever(),
  });
  const { res: resPost, fetchData: postOpdrachtgever } = useFetch('/api/opdrachtgever', {
    method: 'POST',
    lateFetch: true,
    resType: RES_TYPES.text,
    defaultValue: '',
  });
  const { fetchData: putOpdrachtgever, setUrl: setPutUrl } = useFetch('', {
    method: 'PUT',
    lateFetch: true,
    resType: RES_TYPES.text,
    defaultValue: undefined,
  });

  const [selectedBouwdeel, setSelectedBouwdeel] = useState<Bouwdeel>();
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [newOpdrachtgever, setNewOpdrachtgever] = useState<Opdrachtgever>(createEmptyOpdrachtgever());

  const refreshBouwdeel = () => {
    setSelectedBouwdeel(undefined);
    fetchOpdrachtgever();
  };

  const refreshProject = () => {
    setSelectedProject(undefined);
    fetchProjecten();
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      if (opdrachtgever) {
        setNewOpdrachtgever(createEmptyOpdrachtgever());
        setUrls('');
      }
    } else {
      setUrls(id);
    }
  }, [id]);

  const setUrls = (id: string) => {
    setProjectenUrl(id ? `/api/project?opdrachtgever=${id}` : '');
    setOpdrachtgeverUrl(id ? `/api/opdrachtgever/${id}` : '');
    setPutUrl(id ? `/api/opdrachtgever/${id}` : '');
  };

  useEffect(() => {
    setNewOpdrachtgever(prevState => ({
      ...prevState,
      ...opdrachtgever,
    }));
  }, [opdrachtgever]);

  useEffect(() => {
    if (resPost && resPost.ok) {
      navigate('/users');
    }
  }, [resPost]);

  const refresh = () => {
    updateSelectedOption('instellingen', '');
    navigate('/instellingen');
  };

  const addOpdrachtgever = () => {
    if (id !== 'new' && opdrachtgever && opdrachtgever.id) {
      putOpdrachtgever(JSON.stringify(newOpdrachtgever));
    } else {
      postOpdrachtgever(JSON.stringify(newOpdrachtgever));
    }
  };

  const updateOpdrachtgever = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setNewOpdrachtgever(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  if (!id || !(id === 'new' || newOpdrachtgever.id)) {
    return <></>;
  }
  return (
    <div className="opdrachtgever">
      <h1 className="opdrachtgevers-header">{id != 'new' && newOpdrachtgever ? newOpdrachtgever.name : 'Nieuw'}</h1>
      <div className="chair-item-header">
        <FaSave className="update-icon" onClick={() => addOpdrachtgever()} title="Opslaan" />
        {newOpdrachtgever && <DeleteComponent path={`/api/opdrachtgever/${id}`} name={newOpdrachtgever.name} refresh={refresh} />}
      </div>
      <div className="property-big">
        <label htmlFor='name'>Naam</label>
        <input type="text" name="name" id="name" value={newOpdrachtgever.name} onChange={updateOpdrachtgever}></input>
      </div>
      <div className="sub-tabs">
        <div className={'sub-tab ' + (selectedTab === 0 ? 'active' : '')} onClick={() => setSelectedTab(0)} data-testid='Projecten'>
          <span>Projecten</span>
        </div>
        <div className={'sub-tab ' + (selectedTab === 1 ? 'active' : '')} onClick={() => setSelectedTab(1)} data-testid='Bouwdelen'>
          <span>Bouwdelen</span>
        </div>
      </div>
      {id !== 'new' && (
        <>
          {selectedTab === 1 && (
            <div className="tabbed-container">
              <div className="bouwdelen">
                {newOpdrachtgever.bouwdelen
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(bd => (
                    <div key={bd.id} className={'bouwdeelItem ' + (bd.id === selectedBouwdeel?.id ? 'selected' : '')} onClick={() => setSelectedBouwdeel(bd)}>
                      <span>{bd.name}</span>
                    </div>
                  ))}
              </div>
              <BouwdeelPage bouwdeel={selectedBouwdeel} oprdachtgever_id={id || ''} refresh={refreshBouwdeel} />
            </div>
          )}
          {selectedTab === 0 && (
            <div className="tabbed-container">
              <div className="bouwdelen">
                {projecten
                  .sort((a, b) => a.project_number.localeCompare(b.project_number))
                  .map(project => (
                    <div
                      key={project.id}
                      className={'bouwdeelItem ' + (project.id === selectedProject?.id ? 'selected' : '')}
                      onClick={() => setSelectedProject(project)}>
                      <span>{project.project_number}</span>
                    </div>
                  ))}
              </div>
              <ProjectPage project={selectedProject} refresh={refreshProject} bouwdelen={opdrachtgever.bouwdelen} />
            </div>
          )}{' '}
        </>
      )}
    </div>
  );
};

