import React, { useEffect, useState } from 'react';
import { Bouwdeel, IdRegistration } from '../../../common/types';
import { useFetch } from '../../../hooks/useFetch';
import "../IdRegistrationPage.css"

export interface BouwdeelProps {
  registration: IdRegistration;
  opdrachtgever: string;
  updateRegistration: (name: string, value: string | Bouwdeel | undefined) => void;
}

export const BouwdeelComponent = ({ registration, opdrachtgever, updateRegistration }: BouwdeelProps) => {
  const { data: bouwdelen, setUrl: setBouwdeelUrl } = useFetch<Bouwdeel[]>('', {
    defaultValue: [],
  });
  const [bouwdeelId, setBouwdeelId] = useState('');
  const [bouwdeelName, setBouwdeelName] = useState('');

  useEffect(() => {
    setBouwdeelName(registration.project?.bouwdeel?.name || '');
  }, [registration.project]);

  useEffect(() => {
    setBouwdeelId(registration.bouwdeel?.id || '');
  }, [registration.bouwdeel]);

  useEffect(() => {
    setBouwdeelUrl(`/api/bouwdeel?opdrachtgever=${opdrachtgever}`);
  }, [opdrachtgever, setBouwdeelUrl]);

  useEffect(() => {
    if (registration.project) {
      updateRegistration('bouwdeel_id', '');
      updateRegistration('bouwdeel', undefined);
    }
  }, [registration.project]);

  const updateBouwdeel = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const bouwdeel = bouwdelen.find(bd => bd.id === event.target.value);
    updateRegistration('bouwdeel', bouwdeel);
  };

  return (
    <div className="property">
      <label>Bouwdeel</label>
      {bouwdeelName ? (
        <input type="text" disabled value={bouwdeelName} />
      ) : (
        <select name="bouwdeel_id" value={bouwdeelId} onChange={updateBouwdeel} className={bouwdeelId ? '' : 'required'}>
          <option value="">Selecteer bouwdeel</option>
          {bouwdelen.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};
