import React, { ChangeEvent, useEffect, useState } from 'react';

import '../Page.css';
import './BouwdeelPage.css';

import { Bouwdeel, Gateway } from '../../common/types';
import { RES_TYPES, useFetch } from '../../hooks/useFetch';
import { FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import { DeleteComponent } from '../../components/DeleteComponent';

type Props = {
  bouwdeel?: Bouwdeel;
  oprdachtgever_id: string;
  refresh: () => void;
};

const createEmptyBouwdeel = (oprdachtgever_id: string) => {
  return {
    name: '',
    gateways: [] as Gateway[],
    description: '',
    doi_code: '',
    opdrachtgever_id: oprdachtgever_id,
  } as Bouwdeel;
};

export const BouwdeelPage = ({ bouwdeel, oprdachtgever_id, refresh }: Props) => {
  const { res: resPost, fetchData: postBouwdeel } = useFetch('/api/bouwdeel', {
    method: 'POST',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });
  const {
    res: resPut,
    fetchData: putBouwdeel,
    setUrl: setPutUrl,
  } = useFetch('', {
    method: 'PUT',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });

  const [newBouwdeel, setNewBouwdeel] = useState<Bouwdeel>(createEmptyBouwdeel(oprdachtgever_id));
  const [newGateway, setNewGateway] = useState('');

  useEffect(() => {
    if (bouwdeel) {
      setPutUrl(`/api/bouwdeel/${bouwdeel.id}`);
      setNewBouwdeel(prevState => ({
        ...prevState,
        ...bouwdeel,
      }));
    }
  }, [bouwdeel]);

  useEffect(() => {
    if ((resPost && resPost.ok) || (resPut && resPut.ok)) {
      close();
    }
  }, [resPost, resPut]);

  const close = () => {
    setNewBouwdeel(createEmptyBouwdeel(oprdachtgever_id));
    setPutUrl('');
    refresh();
  };

  const updateBouwdeel = (event: ChangeEvent<HTMLInputElement>) => {
    setNewBouwdeel(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const addGateway = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Enter' || newGateway === '') return;
    const parts = newGateway.split('.');
    if (!(parts.length === 4 &&
      parts.every(part => parts.length > 0 && !isNaN(+part) && part != '' && Number(part) >= 0 && Number(part) < 255))) {
      alert('Gateway is niet correct');
      return;
    }

    setNewBouwdeel(prevState => ({
      ...prevState,
      gateways: [...prevState.gateways, { ip_address: newGateway }],
    }));
    setNewGateway('');
  };

  const removeGateway = (index: number) => {
    setNewBouwdeel(prevState => ({
      ...prevState,
      gateways: prevState.gateways.filter((_, i) => i !== index),
    }));
  };

  const addBouwdeel = () => {
    let property: keyof typeof newBouwdeel;
    for (property in newBouwdeel) {
      if (!newBouwdeel[property] && property !== 'description') {
        alert('Niet alles is correct ingevuld');
        return;
      }
    }
    if (bouwdeel) {
      putBouwdeel(JSON.stringify(newBouwdeel));
    } else {
      postBouwdeel(JSON.stringify(newBouwdeel));
    }
  };

  return (
    <div className="chair-item">
      <h2>{bouwdeel ? bouwdeel.name : 'Nieuw'}</h2>
      <div className="chair-item-header">
        <FaSave className="update-icon" onClick={() => addBouwdeel()} title="Opslaan" />
        {bouwdeel && <DeleteComponent path={`/api/bouwdeel/${bouwdeel.id}`} name={newBouwdeel.name} refresh={close} />}
      </div>
      <div className="property">
        <label htmlFor="name">Naam</label>
        <input maxLength={4} type="text" id="name" name="name" value={newBouwdeel.name} onChange={updateBouwdeel} placeholder='Bouwdeelcode 4 karakters'></input>
      </div>
      <div className="property">
        <label htmlFor="description">Beschrijving</label>
        <input maxLength={255} type="text" id="description" name="description" value={newBouwdeel.description} onChange={updateBouwdeel}></input>
      </div>
      <div className="property">
        <label htmlFor="doi_code">DOI code</label>
        <input maxLength={1} type="text" id="doi_code" name="doi_code" value={newBouwdeel.doi_code} onChange={updateBouwdeel} placeholder='DOI code 1 getal'></input>
      </div>
      <div className="property">
        <label htmlFor="gateway">Gateways</label>
        <div className='gateway-items'>
          {newBouwdeel.gateways.map((gateway, index) => (
            <div key={index} className="gateway-item">
              <span>{gateway.ip_address}</span>
              <FaTrash className="update-icon gateway-remove" onClick={() => removeGateway(index)} title="Verwijder Gateway" />
            </div>
          ))}
          <div className="gateway-item">
            <input type="text" id="gateway" name="gateway" value={newGateway} onChange={(event) => setNewGateway(event.target.value)} onKeyDown={addGateway}></input>
            <FaPlus className="update-icon" onClick={() => addGateway({ key: 'Enter' } as React.KeyboardEvent<HTMLInputElement>)} title="Add" />
          </div>
        </div>
      </div>

      {bouwdeel && (
        <div className="property">
          <button onClick={() => close()}>Cancel</button>
        </div>
      )}
    </div>
  );
};
