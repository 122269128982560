export enum Status {
  REQUEST = 'Aanvraag',
  REGISTERED = 'Geregistreerd',
  TESTED = 'Getest',
  OPERATIONAL = 'Operationeel',
  MIGRATED = 'Gemigreerd',
}

export enum Installation {
  NAREGELING = 'Naregeling',
  PRIMAIR = 'Primaire Installatie',
  TIJDELIJK = 'Tijdelijke DOI',
}

export interface FetchResult<T> {
  data: T;
  res: Response | null;
  loading: boolean;
  error: string |  null;
  fetchData: (body?: BodyInit | null, controller?: AbortController) => Promise<void>;
  setUrl: (url: string) => void
}

export type Gateway = {
  id?: string;
  ip_address: string;
}

export type Role = {
  id?: string;
  name: string;
}

export type User = {
  id: string;
  username: string;
  password?: string;
  roles: Role[];
  opdrachtgevers: UserOpdrachtgever[];
}

export type UserProfile = {
  user: User;
  roles: Role[];
}

export type UserOpdrachtgever = {
  id?: string;
  opdrachtgever: Opdrachtgever;
  opdrachtgever_id: string;
  user_id: string;
}

export type Opdrachtgever = {
  id?: string;
  name: string;
  bouwdelen: Bouwdeel[];
}

export type AuditLog = {
  id: string;
  entity: string;
  logtime: string;
  action: string;
  data: string;
  additional_data: string;
}

export type IdRegistration = {
  id?: string;
  timestamp?: string;
  controller_name: string;
  doi: number;
  installation: string;
  floor: string;
  location: string;
  gateway: string;
  ip_address: string;
  mac_address: string;
  to: string;
  process: string;
  notes: string;
  network_number: number;
  vendor?: Vendor;
  vendortype?: Vendortype;
  project?: Project;
  bouwdeel?: Bouwdeel;
  beyond?: Beyond;
  parent_beyond?: Beyond;
  status: Status;
  opdrachtgever_id: string;
}

export type Bouwdeel = {
  id: string;
  name: string;
  gateways: Gateway[];
  opdrachtgever_id: string;
  description: string;
  doi_code: string;
}

export type Vendor = {
  id: string;
  name: string;
}

export type Vendortype = {
  id: string;
  vendor_id: string;
  name: string;
}

export type Project = {
  id?: string;
  project_number: string;
  description?: string;
  bouwdeel_id: string;
  bouwdeel?: Bouwdeel;
}

export type Beyond = {
  id?: string;
  gateway: string;
  network_number: number;
}