import React, { useEffect } from 'react';

import { Bouwdeel, IdRegistration } from '../../../common/types';

export type GatewayProps = {
  registration: IdRegistration;
  updateRegistration: (name: string, value: string) => void;
  bouwdeel?: Bouwdeel;
};

export const GatewayComponent = ({ registration, updateRegistration, bouwdeel }: GatewayProps) => {
  useEffect(() => {
    if (registration.parent_beyond) {
      updateRegistration('gateway', registration.parent_beyond.gateway);
    }
  }, [registration.parent_beyond]);

  return (
    <div className="property">
      <label htmlFor="gateway">Gateway</label>
      {
        !registration.parent_beyond && bouwdeel ? (
          <select
            name="gateway"
            value={registration.gateway}
            onChange={e => updateRegistration('gateway', e.target.value)}>
            <option value="">Selecteer een gateway</option>
            {bouwdeel.gateways.map((option, index) => (
              <option key={index} value={option.ip_address}>
                {option.ip_address}
              </option>
            ))}
          </select>
        ) : (
          <input type="text" id="gateway" name="gateway" value={registration.gateway || ''} disabled={true}></input>
        )
      }
    </div>
  );
};
