import React, { useEffect, useState } from 'react';

import './StatusComponent.css';
import { IdRegistration, Status } from '../../../common/types';

export type StatusProps = {
  registration: IdRegistration;
  updateRegistration: (name: string, value: Status) => void;
};

export const StatusComponent = ({ registration, updateRegistration }: StatusProps) => {
  useEffect(() => {
    let status: Status;
    if(!registration.ip_address || !registration.doi) {
      status = Status.REQUEST;
    } else if (!registration.mac_address) {
      status = Status.REGISTERED;
    } else if (!registration.to) {
      status = Status.TESTED;
    } else {
      status = Status.OPERATIONAL;
    }

    if(status !== registration.status && registration.status !== Status.MIGRATED) {
      updateRegistration('status', status);
    }
  }, [registration.ip_address, registration.doi, registration.mac_address, registration.to, updateRegistration]);

  return (
    <StatusViewComponent status={registration.status || Status.REQUEST} />
  )
};

type StatusViewProps = {
  status: Status;
};

export const StatusViewComponent = ({ status }: StatusViewProps) => {
  const [statusText, setStatusText] = useState<string>('');
  const [statusClass, setStatusClass] = useState<string>('');

  useEffect(() => {
    setStatusText(status);
    switch (status) {
      case Status.REQUEST:
        setStatusClass('grey');
        break;
      case Status.REGISTERED:
        setStatusClass('yellow');
        break;
      case Status.TESTED:
        setStatusClass('orange');
        break;
      default:
        setStatusClass('green');
    }
  }, [status]);

  return (
    <div className="status">
      <span className={'dot ' + statusClass}></span>
      <span className="status-text">{statusText}</span>
    </div>
  );
};
