import React from 'react';
import { IdRegistration } from '../../../common/types';

import { verdiepingen } from '../../../common/consts';

type ExportRegistrationsButtonProps = {
  name: string;
  data: IdRegistration[];
};

const headers = [
  'Controllernaam',
  'DOI',
  'Installatie',
  'Verdieping',
  'Bouwdeel',
  'Locatie',
  'Vendor',
  'Vendortype',
  'IP-adres',
  'MAC-adres',
  'Technical Outlet',
  'Notitie',
];

export const ExportRegistrationsButton = (props: ExportRegistrationsButtonProps) => {
  const convertData = () => {
    const verdiepingenByKey = verdiepingen.reduce(
      (result, current) => {
        result[current.value] = current.name;
        return result;
      },
      {} as { [key: string]: string }
    );
    props.data
      .forEach(reg => {
        reg.controller_name = reg.controller_name 
          || ((reg.project?.bouwdeel || reg.bouwdeel)?.name || '') + reg.location + 'D' + (reg.doi || '')
      })
    return (
      headers.join(',') +
      '\n' +
      props.data
        .map(reg => {
          return [
            reg.controller_name,
            reg.doi,
            reg.installation,
            verdiepingenByKey[reg.floor],
            (reg.project?.bouwdeel || reg.bouwdeel)?.name || '',
            reg.location,
            reg.vendor?.name || '',
            reg.vendortype?.name || '',
            reg.ip_address,
            reg.mac_address,
            reg.to,
            reg.notes,
          ].join(',');
        })
        .join('\n')
    );
  };

  const exportData = () => {
    const blob = new Blob([convertData()], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = props.name + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <button onClick={() => exportData()}>Export</button>
    </>
  );
};
