import React from 'react';

import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Profile } from './Profile';
import UsersPage from './pages/UsersPage';
import { LoginPage, LogoutPage } from './pages/LoginOutPage';
import { ChnLayoutPage } from '@chainless/chainless-react';
import { useAuthContext } from './authProvider';
import { InstellingenPage } from './pages/Instellingen/InstellingenPage';
import { IdRegistrationsPage } from './pages/IdRegistrations/IdRegistrationsPage';
import { BreadcrumbsComponent } from './components/BreadcrumbsComponent';
import UserPage from './pages/UserPage';
import { AuditLogsPage } from './pages/AuditLogs/AuditLogsPage';

function App() {
  const { roles } = useAuthContext();

  const tabs = [
    {
      name: 'Identifier Registraties',
      route: '/id-registraties',
    },
    {
      name: 'Instellingen',
      route: '/instellingen',
    },
    {
      name: 'Users',
      route: '/users',
      roles: ['admin'],
    },
    {
      name: 'Auditlogs',
      route: '/auditlogs',
      roles: ['admin'],
    },
    {
      name: 'Logout',
      route: '/logout',
    },
  ];

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <ChnLayoutPage tabs={tabs.filter(tab => !tab.roles || tab.roles.some(role => roles.includes(role)))} name={'ChaIR'}>
          </ChnLayoutPage>
          <Profile />
        </>
      ),
      children: [
        {
          path: '',
          element: <BreadcrumbsComponent />,
          children: [
            {
              path: '/id-registraties',
              element: <IdRegistrationsPage />,
            },
            {
              path: '/id-registraties/:id',
              element: <IdRegistrationsPage />,
            },
            {
              path: 'instellingen',
              element: <InstellingenPage />,
            },
            {
              path: 'instellingen/:id',
              element: <InstellingenPage />,
            },
            {
              path: '/users',
              element: roles.includes('admin') ? <UsersPage /> : <></>,
            },
            {
              path: '/users/:id',
              element: roles.includes('admin') ? <UserPage /> : <></>,
            },
            {
              path: '/auditlogs',
              element: roles.includes('admin') ? <AuditLogsPage /> : <></>,
            },
          ]
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/logout',
          element: <LogoutPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
