import React, { useEffect } from 'react';
import { RES_TYPES, useFetch } from '../hooks/useFetch';
import { FaTrash } from 'react-icons/fa';

export interface DeleteProps {
  path: string;
  name: string;
  refresh: () => void;
}

export const DeleteComponent = ({ path, name, refresh }: DeleteProps) => {
  const {
    data,
    res: resDel,
    fetchData: deleteData,
    setUrl: setDelUrl,
  } = useFetch('', {
    method: 'DELETE',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });

  useEffect(() => {
    if (path) {
      setDelUrl(path);
    }
  }, [path]);

  
  useEffect(() => {
    if ((resDel)) {
      if(resDel.ok) {
        refresh();
      } else {
        resDel.text().then((text) => { alert(text); });
      }
    }
  }, [resDel, data]);

  
  const deleteAction = () => {
    const confirmDelete = window.confirm(`Weet u zeker dat u ${name} wil verwijderen?`);
    if (confirmDelete) {
      deleteData();
    }
  };

  return (
    <FaTrash className="update-icon" onClick={() => deleteAction()} title="Verwijder" />
  );
};
