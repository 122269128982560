import React, { ReactNode, createContext, useContext, useState } from 'react';

export interface CrumbOptionsContextType {
  options: {[_: string]: {id: string, name: string}[]};
  updateOptions: (name: string, newOptions: {id: string, name: string}[]) => void;
  ids: {[_:string]: { name: string; group: string }};
  selectedOption: {[_: string]: string};
  updateSelectedOption: (name: string, value: string) => void;
}

export const CrumbOptionsContext = createContext<CrumbOptionsContextType | null>(null);

export const useCrumbOptionsContext = () => {
  const context = useContext(CrumbOptionsContext);
  if (!context) {
    throw new Error('useCrumbOptionsContext must be used within a CrumbOptionsProvider');
  }
  return context;
}

export const CrumbOptionsProvider = ({ children }: { children: ReactNode }) => {
  const [options, setOptions] = useState({} as {[_: string]: {id: string, name: string}[]});
  const [ids, setIds] = useState({} as {[_:string]: { name: string; group: string }});
  const [selectedOption, setSelectedOption] = useState({} as {[_: string]: string});

  const updateOptions = (name: string, newOptions: {id: string, name: string}[]) => {
    setOptions(prevState => ({
      ...prevState,
      [name]: newOptions,
    }));

    setIds(prevState => ({
      ...prevState,
      ...newOptions.reduce((result, option) => {
        result[option.id] = {
          name: option.name,
          group: name
        }
        return result;
      }, {} as { [_: string]: {name: string; group: string}})
    }))
  }

  const updateSelectedOption = (name: string, value: string) => {
    setSelectedOption(prevState => ({
      ...prevState,
      [name]: value,
    }));
  
  }

  return (
    <CrumbOptionsContext.Provider
      value={{
        options,
        updateOptions,
        ids,
        selectedOption,
        updateSelectedOption,
      }}>
      {children}
    </CrumbOptionsContext.Provider>
  );
};
