import { IdRegistration } from "./types";


export const getIdRegistrationName = (reg: IdRegistration) => {
  if (reg.controller_name) {
    return reg.controller_name;
  }
  if (reg.bouwdeel) {
    return reg.bouwdeel.name + reg.location + 'D' + reg.doi;
  }
  if (reg.project && reg.project.bouwdeel) {
    return reg.project.bouwdeel.name + reg.location + 'D' + reg.doi;
  }
  return '';
}