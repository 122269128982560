import { useCallback, useEffect, useState } from "react";
import { FetchResult } from "../common/types";

export const RES_TYPES = {
  json: 'JSON',
  text: 'TEXT',
  blob: 'BLOB',
  empty: 'EMPTY'
};

type OptionsType<T> = {
  defaultValue: T,
  method?: string,
  lateFetch?: boolean,
  resType?: string;
}

const defaultOptions = {
  method: 'GET',
  lateFetch: false,
  resType: RES_TYPES.json
}

export const useFetch = <T,>(initUrl: string, options: OptionsType<T>): FetchResult<T> => {
  const [data, setData] = useState(options.defaultValue);
  const [res, setRes] = useState<Response | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [options_, setOptions_] = useState({...defaultOptions, ...options});
  const [url, setUrl] = useState(initUrl);


  const setStates = (loading: boolean, error: string | null, data: T | undefined, res: Response | null) => {
    setLoading(loading);
    setError(error);
    setData(d => data !== undefined ? data : d);
    setRes(res);
  };

  const getCsrf = () => {
    return document.cookie.split('; ').filter(row => row.startsWith('csrf_access_token=')).map(c=>c.split('=')[1])[0] || ''
  }

  const fetchData = useCallback(async (body: BodyInit | undefined | null, controller: AbortController | undefined) => {
    setStates(true, null, undefined, null);
    const fetchOptions = {
      ...options_
    };
    let res;
    try {
      res = await fetch(url, {
        method: fetchOptions.method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCsrf()
        },
        credentials: 'include',
        ...(controller ? {signal: controller.signal} : {}),
        body
      });
    } catch (e) {
      if (e instanceof Error && e.name !== 'AbortError') {
        setStates(false, e.message, undefined, null);
      }
      return;
    }
    if (!res.ok) {
      setStates(false, res.statusText, undefined, res);
    } else if (fetchOptions.resType === RES_TYPES.empty) {
      setStates(false, null, {emtpy: true} as T, res); // use object so react change detection is triggered
    } else if (fetchOptions.resType === RES_TYPES.text) {
      setStates(false, null, await res.text() as T, res);
    } else if (fetchOptions.resType === RES_TYPES.blob) {
      setStates(false, null, await res.blob() as T, res);
    } else if (fetchOptions.resType === RES_TYPES.json) {
      try {
        setStates(false, null, await res.json() as T, res);
      } catch (e) {
        if (e instanceof Error && e.name !== 'AbortError') {
          setStates(false, e.message, undefined, res);
        }
      }
    }
  }, [url, options_]);

  useEffect(() => {
    setOptions_((o) => {
      return {
        ...o,
        ...(o.lateFetch === undefined && o.method !== 'GET' ? {lateFetch: true} : {}),
        ...(!Object.values(RES_TYPES).includes(o.resType) ? { resType: RES_TYPES.json} : {})
      };
    });
  }, []);

  useEffect(() => {
    if (!options_.lateFetch && url) {
      const controller = new AbortController();
      fetchData(undefined, controller);
      return () => {
        controller.abort();
      };
    }

  }, [options_.lateFetch, url]);

  return {data, error, loading, res, fetchData, setUrl};
};
