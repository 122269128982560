import React, { useEffect } from 'react';
import { Project } from '../../../common/types';
import { useFetch } from '../../../hooks/useFetch';

interface ProjectProps {
  project?: Project;
  opdrachtgever: string;
  updateRegistration: (name: string, value: string | number | Project | undefined) => void;
}

export const ProjectComponent = ({ project, opdrachtgever, updateRegistration }: ProjectProps) => {
  const { data: projects, setUrl: setProjectUrl } = useFetch<Project[]>('', {
    defaultValue: [],
  });

  useEffect(() => {
    setProjectUrl(`/api/project?opdrachtgever=${opdrachtgever}`);
  }, [opdrachtgever, setProjectUrl]);

  const updateProject = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const project = projects.find(pr => pr.id === event.target.value);
    updateRegistration('project', project);
  };

  return (
    <div className="property">
      <label htmlFor="project_id">Project</label>
      <select id="project_id" value={project?.id || ''} onChange={updateProject}>
        <option value="">Geen project</option>
        {projects.map((option, index) => (
          <option key={index} value={option.id}>
            {option.project_number}
          </option>
        ))}
      </select>
    </div>
  );
};
