import React, { useEffect, useState } from 'react';

import { useFetch } from '../../../hooks/useFetch';
import { Beyond, IdRegistration, Vendor, Vendortype } from '../../../common/types';
import { beyondGateway } from '../../../common/consts';
import "../IdRegistrationPage.css"

type VendorProps = {
  registration: IdRegistration;
  registrations: IdRegistration[];
  updateRegistration: (name: string, value: Beyond | string | undefined | Vendor | Vendortype) => void;
};

export const VendorComponent = ({ registration, registrations, updateRegistration }: VendorProps) => {
  const [localNetworknumber, setLocalNetworknumber] = useState('');
  const [beyond, setBeyond] = useState({ vendor: '', vendortype: '' });

  const [beyonds, setBeyonds] = useState<Beyond[]>([]);

  const { data: dataVendors } = useFetch<Vendor[]>('/api/vendor', {
    defaultValue: [],
  });
  const { data: dataVendortypes } = useFetch<Vendortype[]>('/api/vendortype', {
    defaultValue: [],
  });

  useEffect(() => {
    setBeyond(prevState => ({
      ...prevState,
      vendor: dataVendors.find(v => v.name === 'Chainless')?.id || '',
    }));
  }, [dataVendors]);

  useEffect(() => {
    setBeyond(prevState => ({
      ...prevState,
      vendortype: dataVendortypes.find(v => v.name === 'Beyond')?.id || '',
    }));
  }, [dataVendortypes]);

  useEffect(() => {
    setLocalNetworknumber('');
  }, [registration.id]);

  useEffect(() => {
    setBeyonds(registrations.map(reg => reg.beyond).filter((beyond): beyond is Beyond => !!beyond));
  }, [registrations]);

  //Default vendor
  useEffect(() => {
    if (dataVendors.length > 0 && !registration.vendor) {
      updateRegistration('vendor', dataVendors[0]);
    }
  }, [dataVendors, updateRegistration, registration.vendor]);

  useEffect(() => {
    if (registration.vendor && registration.vendortype && registration.vendor.id === beyond.vendor && registration.vendortype.id === beyond.vendortype) {
      if (!registration.beyond) {
        updateRegistration('beyond', generateBeyond(beyonds)[0]);
      }
    } else {
      if (registration.beyond) {
        updateRegistration('beyond', undefined);
      }
    }
  }, [registration.vendor, registration.vendortype]);

  useEffect(() => {
    const newNetworknumber = Number(localNetworknumber);
    if (!registration.beyond) {
      return;
    }
    if (isCorrect(newNetworknumber)) {
      updateRegistration('beyond', { ...registration.beyond, network_number: newNetworknumber });
    } else if (localNetworknumber !== '') {
      updateRegistration('beyond', { ...registration.beyond, network_number: -1 });
    }
  }, [localNetworknumber]);

  const isCorrect = (value: number) => {
    return (
      beyonds.findIndex(by => by.network_number === value && by.id !== registration.beyond?.id) === -1 &&
      value > 0 &&
      value <= 4194304 &&
      localNetworknumber !== ''
    );
  };

  const updateBeyond = (value: string) => {
    if (registration.beyond && !isNaN(+value)) {
      setLocalNetworknumber(value);
    }
  };

  return (
    <>
      <div className="property">
        <label>Vendor</label>
        <select
          name="vendor_id"
          data-testid="vendor-select"
          value={registration.vendor && registration.vendor.id}
          onChange={e => updateRegistration('vendor', dataVendors.find(v => v.id === e.target.value))}>
          {dataVendors.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      <div className="property">
        <label>Vendor-Type</label>
        <select
          className={registration?.vendortype ? '' : 'required'}
          data-testid="vendortype-select"
          disabled={!registration.vendor}
          name="vendortype_id"
          value={registration.vendortype && registration.vendortype.id}
          onChange={e => updateRegistration('vendortype', dataVendortypes.find(v => v.id === e.target.value))}>
          <option value="">Keuze</option>
          {dataVendortypes
            .filter(v => registration.vendor && v.vendor_id === registration.vendor.id)
            .map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
        </select>
      </div>
      {registration.beyond && (
        <>
          <div className="property">
            <label htmlFor="beyond_networknumber">Beyond Netwerknummer</label>
            <input
              type="number"
              id="beyond_networknumber"
              name="beyond_networknumber"
              className={registration.beyond.network_number === -1 ? 'error' : ''}
              value={localNetworknumber || registration.beyond.network_number}
              onChange={e => updateBeyond(e.target.value)}></input>
          </div>
        </>
      )}
    </>
  );
};

export const generateBeyond = (beyonds: Beyond[], amount = 1) => {
  const result = [] as number[];
  const existingNetworknumbers = beyonds.map(beyond => beyond.network_number);
  [...Array(9989).keys()]
    .map(n => n + 10)
    .some(val => {
      if (!existingNetworknumbers.includes(val)) {
        result.push(val);
      }
      return result.length === amount;
    });
  return result.map(val => ({ gateway: beyondGateway, network_number: val }));
};
