import React, { useEffect, useState } from 'react';

import './IdRegistrationsPage.css';

import { ChnSortSearchTable } from '@chainless/chainless-react';
import { useFetch } from '../../hooks/useFetch';
import { IdRegistration, Opdrachtgever, Project, Status, UserOpdrachtgever } from '../../common/types';
import { IdRegistrationPage } from './IdRegistrationPage';
import { ExportRegistrationsButton } from './components/ExportRegistrationsButton';
import { FaPencilAlt, FaStickyNote } from 'react-icons/fa';
import { useCrumbOptionsContext } from '../../crumbOptionsProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusViewComponent } from './components/StatusComponent';
import { getIdRegistrationName } from '../../common/util';

export const IdRegistrationsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: idRegistrationData, fetchData: fetchIdRegistration } = useFetch<IdRegistration[]>('/api/id-registration', { defaultValue: [] });
  const { data: opdrachtgeverData } = useFetch<UserOpdrachtgever[]>('/api/user-opdrachtgever', { defaultValue: [] });

  const [idRegistrations, setIdRegistrations] = useState<IdRegistration[]>([]);
  const [selectedIdRegistration, setSelectedIdRegistration] = useState<string>('');
  const [filteredIdRegistrations, setFilteredIdRegistrations] = useState<IdRegistration[]>([]);
  const [opdrachtgevers, setOpdrachtgevers] = useState<Opdrachtgever[]>([]);
  const [opdrachtgever, setOpdrachtgever] = useState<Opdrachtgever>();
  const { updateOptions, selectedOption } = useCrumbOptionsContext();

  useEffect(() => {
    setIdRegistrations(idRegistrationData.filter(reg => opdrachtgever?.id === reg.opdrachtgever_id));
  }, [idRegistrationData, opdrachtgever]);

  useEffect(() => {
    if (opdrachtgeverData && opdrachtgeverData.length > 0) {
      setOpdrachtgevers(opdrachtgeverData.map(d => d.opdrachtgever));
      updateOptions(
        'id-registraties',
        opdrachtgeverData.map(d => ({ id: d.opdrachtgever_id, name: d.opdrachtgever.name }))
      );

      //TODO: moet nog anders
      const opdrachtgever = selectedOption['id-registraties'];
      if (opdrachtgever) {
        navigate(`/id-registraties/${opdrachtgever}`);
      } else {
        navigate(`/id-registraties/${opdrachtgeverData[0].opdrachtgever_id}`);
      }
    }
  }, [opdrachtgeverData]);

  const cancel = () => {
    setSelectedIdRegistration('');
  };

  const updateSelectedIdRegistration = (id?: string) => {
    setSelectedIdRegistration(id ? id : '');
  };

  useEffect(() => {
    if (id) {
      setOpdrachtgever(opdrachtgevers.find(o => o.id === id));
      cancel();
    }
  }, [id, opdrachtgevers]);

  const headers = [
    {
      key: 'contr_name',
      name: 'Controller naam',
      valueMapper: getIdRegistrationName,
      className: 'reg-normal',
    },
    {
      key: 'timestamp',
      name: 'Datum',
      valueMapper: (timestamp: string) => {
        if (!timestamp) return '---';
        return timestamp.split('T')[0].split('-').reverse().join('-');
      },
      className: 'reg-small',
    },
    { key: 'installation', name: 'Installatie', className: 'medium' },
    {
      key: 'ip_address',
      name: 'IP-adres',
      valueMapper: (ip: string) => (ip ? ip : '---'),
      className: 'reg-normal'
    },
    { key: 'to', name: 'Technical Outlet', className: 'small' },
    {
      key: 'process',
      name: 'Proces',
      valueMapper: (process: string) => (process ? process : '---'),
      className: 'reg-small',
    },
    {
      key: 'project',
      name: 'Project',
      valueMapper: (project: Project) => (project ? project.project_number : '---'),
      className: 'reg-small',
    },
    {
      key: 'status',
      name: 'Status',
      search: true,
      displayMapper: (status: Status) => <StatusViewComponent status={status}></StatusViewComponent>,
      valueMapper: (status: Status) => status,
      className: 'reg-small',
    },
    {
      key: 'icons',
      name: '',
      search: false,
      valueMapper: (reg: IdRegistration) => (
        <div
          className="div-edit"
          onClick={() => {
            updateSelectedIdRegistration(reg.id);
          }}>
          <FaPencilAlt title="Bewerken" className="update-icon" />
          {reg.notes && <FaStickyNote title="Notities" className="update-icon"></FaStickyNote>}
        </div>
      ),
      className: 'reg-remaining',
    },
  ];

  return (
    <>
      {opdrachtgever && opdrachtgever.id && (
        <div className="registraties-container">
          <div className="registraties-content">
            <h1 className="registraties-header">{opdrachtgever.name}</h1>
            <div className="actions">
              <ExportRegistrationsButton name="registraties" data={filteredIdRegistrations}></ExportRegistrationsButton>
            </div>
            <div className="registraties-table">
              <ChnSortSearchTable
                headers={headers}
                data={idRegistrations}
                filteredDataCallback={(data: IdRegistration[]) => setFilteredIdRegistrations(data)}
              />
            </div>
          </div>
          {
            <IdRegistrationPage
              fetchRegistrations={fetchIdRegistration}
              cancel={cancel}
              registrations={idRegistrations}
              opdrachtgever={opdrachtgever.id}
              registrationId={selectedIdRegistration}></IdRegistrationPage>
          }
        </div>
      )}
    </>
  );
};
