import React, { useEffect, useState } from 'react';

import './Breadcrumbs.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCrumbOptionsContext } from '../crumbOptionsProvider';

export const BreadcrumbComponent = ({ name }: { name: string }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { options, ids, updateSelectedOption } = useCrumbOptionsContext();

  const [displayName, setDisplayName] = useState('');
  const [selectedOption, setSelectedOption] = useState([] as {id: string, name: string}[]);

  useEffect(() => {
    if (name in ids) {
      setDisplayName(ids[name].name);
      setSelectedOption(options[ids[name].group]);
    } else {
      setDisplayName(name.charAt(0).toUpperCase() + name.slice(1));
    }
  }, [options, ids, name]);

  //Write onchange to update navigate with new path containing the selected option
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const parts = pathname.split('/');
    const index = parts.indexOf(name);
    if (event.target.value !== 'new') {
      updateSelectedOption(parts[index - 1], event.target.value);
    }
    navigate(pathname.replace(name, event.target.value));
  };

  return (
    <>
      <div className="breadcrumb">
        {selectedOption.length > 1 ? (
          <select value={name} onChange={handleChange}>
            {options[ids[name].group].map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        ) : (
          displayName
        )}
      </div>
    </>
  );
};
