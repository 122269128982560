import React, { useEffect } from 'react';
import { useFetch } from '../hooks/useFetch';
import { User } from '../common/types';

import './UsersPage.css';
import { useCrumbOptionsContext } from '../crumbOptionsProvider';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../authProvider';

function UsersPage() {
  const { roles } = useAuthContext();
  const navigate = useNavigate();
  const { data: users, res } = useFetch<User[]>('/api/user', {
    defaultValue: [],
  });
  const { updateOptions, selectedOption } = useCrumbOptionsContext();

  useEffect(() => {
    if (res && res.ok) {
      updateOptions('users', [...users.map(d => ({ id: d.id, name: d.username })), ...(roles.includes('admin') ? [{ id: 'new', name: '+ Nieuw' }] : [])]);
      if (selectedOption['users']) {
        navigate(`/users/${selectedOption['users']}`);
      } else {
        navigate(`/users/${users[0].id}`);
      }
    }
  }, [res, users]);

  return <></>;
}

export default UsersPage;
