import { RES_TYPES, useFetch } from '../hooks/useFetch';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import './LoginOutPage.css';
import { useAuthContext } from '../authProvider';

export const LoginPage = () => {
  const {
    fetchData: fetchLogin,
    error,
    res,
  } = useFetch('/api/login', {
    method: 'POST',
    lateFetch: true,
    resType: RES_TYPES.json,
    defaultValue: undefined,
  });
  const { authenticated, setAuthenticated } = useAuthContext();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (res && res.ok) {
      setAuthenticated(true);
    }
  }, [res, setAuthenticated]);

  if (authenticated) {
    return <Navigate to="/id-registraties" />;
  }

  const login = async (username: string, password: string) => {
    fetchLogin(JSON.stringify({ username: username, password: password }));
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        login(username, password);
      }}
      className="login">
      <label htmlFor="name">Gebruikersnaam</label>
      <input
        type="string"
        id="name"
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <br />
      <label htmlFor="password">Wachtwoord</label>
      <input
        type="password"
        id="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      {error && <div className="login-error">Wrong username or password</div>}
      <button className="chn-button" type="submit">
        Login
      </button>
    </form>
  );
};

export const LogoutPage = () => {
  const { res } = useFetch('/api/logout', {
    method: 'POST',
    lateFetch: false,
    resType: RES_TYPES.json,
    defaultValue: undefined,
  });

  const { setAuthenticated, authenticated } = useAuthContext();

  useEffect(() => {
    if (res && res.ok) {
      setAuthenticated(false);
    }
  }, [res, setAuthenticated]);

  if (!authenticated) {
    return <Navigate to="/login" />;
  }
  return <></>;
};
