import { useEffect } from 'react';
import { User } from './common/types';
import { RES_TYPES, useFetch } from './hooks/useFetch';
import React from 'react';
import { useAuthContext } from './authProvider';
import { Navigate } from 'react-router-dom';

export const Profile = () => {
  const { data, fetchData, res } = useFetch<User | undefined>('/api/user/me', {
    method: 'GET',
    lateFetch: false,
    resType: RES_TYPES.json,
    defaultValue: undefined,
  });
  const { setRoles, authenticated, loading, setLoading, setAuthenticated } =
    useAuthContext();

  useEffect(() => {
    if (data) {
      setRoles(data.roles.map(row => row.name));
    } else {
      setRoles([]);
    }
  }, [data, setRoles]);

  useEffect(() => {
    if (res) {
      if (res.ok) {
        setAuthenticated(true);
      }
      setLoading(false);
    }
  }, [res, setLoading, setAuthenticated]);

  useEffect(() => {
    if(authenticated) {
      fetchData();
    } else {
      setRoles([]);
    }
  }, [authenticated]);

  if (loading) {
    return <span>Loading</span>;
  }

  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  return <></>;
};
