export const beyondGateway = '192.168.168.1'

export const verdiepingen = [
  {
    name: 'Kelder -2',
    value: '_2',
  },
  {
    name: 'Onderhuis',
    value: '_1',
  },
  {
    name: 'Begane Grond',
    value: '00',
  },
  {
    name: '1e Verdieping',
    value: '01',
  },
  {
    name: '2e Verdieping',
    value: '02',
  },
  {
    name: '3e Verdieping',
    value: '03',
  },
  {
    name: '4e Verdieping',
    value: '04',
  },
  {
    name: '5e Verdieping',
    value: '05',
  },
  {
    name: '6e Verdieping',
    value: '06',
  },
  {
    name: '7e Verdieping',
    value: '07',
  },
  {
    name: '8e Verdieping',
    value: '08',
  },
  {
    name: '9e Verdieping',
    value: '09',
  },
  {
    name: '10e Verdieping',
    value: '10',
  },
  {
    name: '12e Verdieping',
    value: '12',
  },
  {
    name: '13e Verdieping',
    value: '13',
  },
  {
    name: '14e Verdieping',
    value: '14',
  },
  {
    name: '15e Verdieping',
    value: '15',
  },
  {
    name: '16e Verdieping',
    value: '16',
  },
  {
    name: '17e Verdieping',
    value: '17',
  },
  {
    name: '18e Verdieping',
    value: '18',
  },
  {
    name: '19e Verdieping',
    value: '19',
  },
];

export const roles = ['admin', 'user'];
