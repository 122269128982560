import React, { useEffect } from 'react';

import './ToggleComponent.css';


type ToggleProps = {
  toggle: (text: string) => void;
  falseText: string;
  trueText: string;
  feedback: boolean;
};

export const ToggleComponent = ({toggle, falseText, trueText, feedback}: ToggleProps) => {
  useEffect(() => {
    const checkbox = document.querySelector('.checkbox') as HTMLInputElement;
    checkbox.checked = feedback;
  }, [feedback]);

  return (
    <div className="ip-single-batch-container">
      <div className="ip-single-batch">
        <input data-testid="toggle-component" type="checkbox" className="checkbox" onChange={(event) => toggle(event.target.checked ? trueText : falseText)} />
        <div className="knobs">
          <span>{falseText}</span>
          <span>{trueText}</span>
        </div>
        <div className="layer"></div>
      </div>
    </div>
  );
};
