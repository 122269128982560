import React, { useEffect, useState } from 'react';
import { IdRegistration, Installation } from '../../../common/types';

type LocationProps = {
  registration: IdRegistration;
  updateRegistration: (name: string, value: string) => void;
};

export const LocationComponent = ({registration, updateRegistration}: LocationProps) => {
  const [location, setLocation] = useState<string>('');

  const isCorrect = () => {
    return location.length === 8;
  }

  useEffect(() => {
    if(registration.location === '' && isCorrect()) {
      setLocation(registration.location);
    }
  }, [registration.location]);

  useEffect(() => {
    updateRegistration('location', isCorrect() ? location.toUpperCase() : '');
  }, [location]);

  return (
    <div className="property">
      <label>
        {registration.installation === Installation.PRIMAIR && 'Regelkast'}
        {registration.installation === Installation.NAREGELING && 'Ruimtenummer'}
        {(registration.installation === Installation.TIJDELIJK || !registration.installation) && 'Locatie'}
      </label>
      <input 
        minLength={8}
        maxLength={8}
        data-testid="input-location"
        type="text" name="location"
        value={registration.location === '' ? location : registration.location}
        onChange={event => setLocation(event.target.value)}
        className={registration.location ? '' : 'required'}
      />
    </div>
  );
};
