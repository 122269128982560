import React, { useEffect, useState } from 'react';

import { ChnSortSearchTable } from '@chainless/chainless-react';
import { useFetch } from '../../hooks/useFetch';
import { AuditLog } from '../../common/types';

import './AuditLogsPage.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FiRefreshCcw } from 'react-icons/fi';

export const AuditLogsPage = () => {
  const { data: auditLogsData, setUrl, fetchData: fetchAuditlogs } = useFetch<AuditLog[]>('/api/auditlog', { defaultValue: [] });

  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState<Date | null>(new Date(new Date().setHours(23, 59, 59, 0)));

  useEffect(() => {
    setUrl(`/api/auditlog?start=${startDate?.getTime()}&end=${endDate?.getTime()}`);
  }, [startDate, endDate]);

  const headers = [
    { key: 'logtime', name: 'Tijdstip', className: 'small' },
    { key: 'entity', name: 'Entiteit', className: 'small' },
    { key: 'user_id', name: 'User ID', className: 'small' },
    { key: 'action', name: 'Actie', className: 'small' },
    { key: 'data', name: 'Informatie', className: 'medium' },
    { key: 'additional_data', name: 'Details', className: 'remaining', search: false }
  ];

  return (
    <>
      <div className="auditlogs-container">
        <div className="auditlogs-content">
          <div className="actions">
            <FiRefreshCcw className="refresh" onClick={() => fetchAuditlogs()} title="Opnieuw laden" />
            <DatePicker dateFormat="dd-MM-yyyy" selected={endDate} onChange={(date: Date | null) => setEndDate(date)} />
            <span className="audit-label">Eind</span>
            <DatePicker dateFormat="dd-MM-yyyy" selected={startDate} onChange={(date: Date | null) => setStartDate(date)} />
            <span className="audit-label">Start</span>
          </div>
          <div className="auditlogs-table">
            <ChnSortSearchTable
              headers={headers}
              data={auditLogsData}
            />
          </div>
        </div>
      </div>
    </>
  );
};
