import React, { ChangeEvent, useEffect, useState } from 'react';

import './Page.css';

import { Opdrachtgever, Role, User, UserOpdrachtgever } from '../common/types';
import { RES_TYPES, useFetch } from '../hooks/useFetch';
import { FaSave, FaTrash } from 'react-icons/fa';
import { roles } from '../common/consts';
import { useNavigate, useParams } from 'react-router-dom';
import { useCrumbOptionsContext } from '../crumbOptionsProvider';

const createEmptyUser = () => {
  return {
    username: '',
    password: '',
    roles: [] as Role[],
    opdrachtgevers: [] as UserOpdrachtgever[],
  } as User;
};

const UserPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { updateSelectedOption } = useCrumbOptionsContext();
  const { data: opdrachtgevers } = useFetch<Opdrachtgever[]>('/api/opdrachtgever', { defaultValue: [] });
  const { data: postData, res: resPost, fetchData: postUser } = useFetch<string>('/api/user', {
    method: 'POST',
    lateFetch: true,
    resType: RES_TYPES.text,
    defaultValue: '',
  });
  const { data: user, setUrl: setUserUrl } = useFetch<User>('', { defaultValue: {} as User });
  const {
    fetchData: putUser,
    setUrl: setPutUrl,
  } = useFetch('', { method: 'PUT', lateFetch: true, resType: RES_TYPES.empty, defaultValue: undefined });
  const {
    res: resDel,
    fetchData: deleteUser,
    setUrl: setDelUrl,
  } = useFetch('', { method: 'DELETE', lateFetch: true, resType: RES_TYPES.empty, defaultValue: undefined });
  const [newUser, setNewUser] = useState<User>(createEmptyUser());
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  useEffect(() => {
    if(!id) {
      return;
    }
    
    if (id === 'new') {
      if (user) {
        setNewUser(createEmptyUser());
        setUserUrl('');
      }
    } else {
      setUserUrl(`/api/user/${id}`);
    }
  }, [id]);

  useEffect(() => {
    setNewUser(prevState => ({
      ...prevState,
      ...user,
    }));
    if (user) {
      setDelUrl(`/api/user/${user.id}`);
      setPutUrl(`/api/user/${user.id}`);
    }
  }, [user]);

  useEffect(() => {
    if((resDel && resDel.ok)) {
      updateSelectedOption('users', '');
      navigate('/users');
    }
  }, [resDel]);

  useEffect(() => {
    if(resPost && resPost.ok && postData) {
      updateSelectedOption('users', postData.substring(1, postData.length - 2));
      navigate('/users');
    }
  }, [resPost, postData]);

  const updateUser = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setNewUser(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const addUser = () => {
    let property: keyof typeof newUser;
    for (property in newUser) {
      if (!newUser[property] && !['password'].includes(property)) {
        alert('Niet alles is correct ingevuld');
        return;
      }
    }
    if (!user && !newUser.password) {
      alert('Er moet een wachtwoord worden ingevuld');
      return;
    }
    if (newUser.password && !(newUser.password === repeatPassword)) {
      alert('Wachtwoorden komen niet overeen');
      return;
    }
    if (id !== 'new' && user && user.id) {
      putUser(JSON.stringify(newUser));
    } else {
      postUser(JSON.stringify(newUser));
    }
  };

  const toggleRole = (name: string) => {
    const previousLength = newUser.roles.length;
    const newRoles = newUser.roles.filter(r => r.name !== name);
    if (newRoles.length === previousLength) {
      newRoles.push({
        name: name,
      });
    }
    setNewUser(prevState => ({
      ...prevState,
      roles: newRoles,
    }));
  };

  const toggleOpdrachtgever = (id: string) => {
    const previousLength = newUser.opdrachtgevers.length;
    const newOpdrachtgevers = newUser.opdrachtgevers.filter(og => og.opdrachtgever_id !== id);
    if (newOpdrachtgevers.length === previousLength) {
      newOpdrachtgevers.push({
        opdrachtgever: opdrachtgevers.filter(og => og.id === id)[0],
        opdrachtgever_id: id,
        user_id: newUser.id,
      });
    }
    setNewUser(prevState => ({
      ...prevState,
      opdrachtgevers: newOpdrachtgevers,
    }));
  };

  if (!id || !(id === 'new' || newUser.id)) {
    return <></>;
  }

  return (
    <div className="user-page">
      <div className="chair-item">
        <h2>{id != 'new' && newUser ? newUser.username : 'Nieuw'}</h2>
        <div className="chair-item-header">
          <FaSave className="update-icon" onClick={() => addUser()} title="Opslaan" />
          {user && <FaTrash className="update-icon" onClick={() => deleteUser()} title="Verwijder" />}
        </div>
        <div className="property">
          <label>Naam:</label>
          <input type="text" name="username" value={newUser.username} onChange={updateUser}></input>
        </div>
        <div className="property">
          <label>Wachtwoord:</label>
          <input type="password" name="password" value={newUser.password} onChange={updateUser}></input>
        </div>
        <div className="property">
          <label>Herhaal wachtwoord:</label>
          <input type="password" name="password-repeat" value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)}></input>
        </div>
        <div className="roles">
          <h3>Roles</h3>
          {roles.map(name => (
            <div key={name} className={'roleItem ' + (newUser.roles.map(role => role.name).includes(name) ? 'selected' : '')} onClick={() => toggleRole(name)}>
              <span>{name}</span>
            </div>
          ))}
        </div>
        <div className="roles">
          <h3>Opdrachtgevers</h3>
          {opdrachtgevers.map(og => (
            <div
              key={og.id}
              className={'roleItem ' + (newUser.opdrachtgevers.map(og => og.opdrachtgever_id).includes(og.id || '') ? 'selected' : '')}
              onClick={() => toggleOpdrachtgever(og.id || '')}>
              <span>{og.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserPage;
