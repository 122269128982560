import React, { useEffect, useState } from 'react';
import { Beyond, Installation, IdRegistration } from '../../../common/types';
import { getIdRegistrationName } from '../../../common/util';

export interface BeyondProps {
  registration: IdRegistration;
  registrations: IdRegistration[];
  updateRegistration: (name: string, value: string | Beyond | undefined) => void;
}

export const BeyondComponent = ({ registration, registrations, updateRegistration }: BeyondProps) => {
  const [regsWithBeyond, setRegsWithBeyond] = useState<IdRegistration[]>([]);
  const [filteredBeyonds, setFilteredBeyonds] = useState<IdRegistration[]>([]);

  useEffect(() => {
    setRegsWithBeyond(registrations.filter(reg => !!reg.beyond));
  }, [registrations]);

  useEffect(() => {
    setFilteredBeyonds(
      regsWithBeyond.filter(
        reg => (reg.bouwdeel?.id || reg.project?.bouwdeel_id) === (registration.bouwdeel?.id || registration.project?.bouwdeel_id)
      )
    );
  }, [regsWithBeyond, registration]);

  useEffect(() => {
    if (registration.parent_beyond && (filteredBeyonds.length === 0 || registration.installation !== Installation.NAREGELING)) {
      updateRegistration('parent_beyond', undefined);
    }
  }, [filteredBeyonds]);

  const updateBeyond = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const by = filteredBeyonds.find(reg => reg.beyond?.id === event.target.value);
    updateRegistration('parent_beyond', by?.beyond);
  };

  return (
    <>
      {registration.installation === Installation.NAREGELING && filteredBeyonds.length > 0 && (
        <div className="property">
          <label>Beyond</label>
          <select data-testid="beyond-select" name="parent_beyond_id" value={registration.parent_beyond && registration.parent_beyond.id} onChange={updateBeyond}>
            <option value="">Keuze</option>
            {filteredBeyonds.map((option, index) => (
              <option key={index} value={option.beyond?.id}>
                {getIdRegistrationName(option)}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};
