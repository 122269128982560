import React, { ReactNode, createContext, useContext, useState } from 'react';

interface AuthContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  authenticated: boolean;
  setAuthenticated: (authenticated: boolean) => void;
  roles: string[];
  setRoles: (roles: string[]) => void;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [roles, setRoles] = useState([] as string[]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        roles,
        setRoles,
        loading,
        setLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
