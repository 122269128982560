import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './authProvider';
import { CrumbOptionsProvider } from './crumbOptionsProvider';


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
    <CrumbOptionsProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </CrumbOptionsProvider>
  // </React.StrictMode>
);
