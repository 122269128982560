import React, { useEffect, useState } from 'react';
import { IdRegistration, Installation } from '../../../common/types';

export interface ProcessProps {
  registration: IdRegistration;
  updateRegistration: (name: string, value: string | number) => void;
}

export const ProcessComponent = ({ registration, updateRegistration }: ProcessProps) => {
  const [localProcess, setLocalProcess] = useState<string>('');

  useEffect(() => {
      updateRegistration('process', isCorrect() ? localProcess : '');
  }, [localProcess, updateRegistration]);

  useEffect(() => {
    setLocalProcess('');
  }, [registration.id]);

  const isCorrect = () => {
    return localProcess.length === 5;
  }

  useEffect(() => {
    if(registration.process === '' && isCorrect()) {
      setLocalProcess(registration.process);
    }
  }, [registration.process]);

  return (
    <div className="property">
      <label>Proces</label>
      <input
        data-testid="process"
        className={registration.process === '' && registration.installation === Installation.NAREGELING ? 'error' : ''}
        name="process"
        value={localProcess === '' ? registration.process : localProcess}
        onChange={event => setLocalProcess(event.target.value)}
        placeholder="Processcode 5 karakters"
      />
    </div>
  );
};
