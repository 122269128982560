import React, { ChangeEvent, useEffect, useState } from 'react';

import '../Page.css';

import { Bouwdeel, Project } from '../../common/types';
import { RES_TYPES, useFetch } from '../../hooks/useFetch';
import { FaSave } from 'react-icons/fa';
import { DeleteComponent } from '../../components/DeleteComponent';

type Props = {
  project?: Project;
  bouwdelen: Bouwdeel[];
  refresh: () => void;
};

const createEmptyProject = () => {
  return {
    project_number: '',
    bouwdeel_id: '',
    description: '',
  } as Project;
};

export const ProjectPage = ({ project, bouwdelen, refresh }: Props) => {
  const { res: resPost, fetchData: postProject } = useFetch('/api/project', {
    method: 'POST',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });
  const {
    res: resPut,
    fetchData: putProject,
    setUrl: setPutUrl,
  } = useFetch('', {
    method: 'PUT',
    lateFetch: true,
    resType: RES_TYPES.empty,
    defaultValue: undefined,
  });

  const [newProject, setNewProject] = useState<Project>(createEmptyProject());

  useEffect(() => {
    if (project) {
      setPutUrl(`/api/project/${project.id}`);
      setNewProject(prevState => ({
        ...prevState,
        ...project,
      }));
    } else if(newProject.project_number !== '') {
      close();
    }
  }, [project]);

  useEffect(() => {
    if ((resPost && resPost.ok) || (resPut && resPut.ok)) {
      close();
    }
  }, [resPost, resPut]);

  const close = () => {
    setNewProject(createEmptyProject());
    setPutUrl('');
    refresh();
  };

  const updateProject = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    setNewProject(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const addProject = () => {
    let property: keyof typeof newProject;
    for (property in newProject) {
      if (!newProject[property]) {
        alert('Niet alles is correct ingevuld ' + property);
        return;
      }
    }
    if (project) {
      putProject(JSON.stringify(newProject));
    } else {
      postProject(JSON.stringify(newProject));
    }
  };

  return (
    <div className="chair-item">
      <h2>{project ? project.project_number : 'Nieuw'}</h2>
      <div className="chair-item-header">
        <FaSave className="update-icon" onClick={() => addProject()} title="Opslaan" />
        {project && <DeleteComponent path={`/api/project/${project.id}`} name={newProject.project_number} refresh={close} />}
      </div>
      <div className="property">
        <label htmlFor='project_number'>Projectnummer</label>
        <input maxLength={36} type="text" id='project_number' name="project_number" value={newProject.project_number} onChange={updateProject}></input>
      </div>
      <div className="property">
        <label htmlFor='description'>Beschrijving</label>
        <input maxLength={255} type="text" id='description' name="description" value={newProject.description} onChange={updateProject}></input>
      </div>
      <div className="property">
        <label htmlFor='bouwdeel'>Bouwdeel</label>
        <select id='bouwdeel' name="bouwdeel_id" value={newProject.bouwdeel_id} onChange={updateProject}>
          <option value="">Selecteer Bouwdeel</option>
          {bouwdelen.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      {project && (
        <div className="property">
          <button onClick={() => close()}>Cancel</button>
        </div>
      )}
    </div>
  );
};
