import React, { useEffect, useState } from 'react';

import './Breadcrumbs.css';
import { Outlet, useLocation } from 'react-router-dom';
import { BreadcrumbComponent } from './BreadcrumbComponent';

export const BreadcrumbsComponent = () => {
  const { pathname } = useLocation();

  const [crumbs, setCrumbs] = useState<string[]>([]);

  useEffect(() => {
    const parts = pathname.split('/').filter(crumb => crumb !== '');
    setCrumbs(parts);
  }, [pathname]);

  return (
    <>
      <div className="breadcrumbs">
        {crumbs.map((crumb, index) => (
          <>
            { index !== 0 && <div className='breadcrumb'>/</div> }
            <BreadcrumbComponent key={crumb} name={crumb}></BreadcrumbComponent>
          </>
        ))}
      </div>
      <Outlet></Outlet>
    </>
  );
};
