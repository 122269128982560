import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './InstellingenPage.css';

import { useFetch } from '../../hooks/useFetch';
import { UserOpdrachtgever } from '../../common/types';
import { useCrumbOptionsContext } from '../../crumbOptionsProvider';
import { useAuthContext } from '../../authProvider';
import { OpdrachtgeverPage } from './OpdrachtgeverPage';

export const InstellingenPage = () => {
  const { roles } = useAuthContext();
  const navigate = useNavigate();
  const { data: opdrachtgevers, res } = useFetch<UserOpdrachtgever[]>('/api/user-opdrachtgever', { defaultValue: [] });
  const { updateOptions, selectedOption } = useCrumbOptionsContext();

  useEffect(() => {
    if (res) {
      if (res.ok) {
        updateOptions('instellingen', [
          ...opdrachtgevers.map(
            d => ({ id: d.opdrachtgever_id, name: d.opdrachtgever.name })
          ),
          ...(roles.includes('admin') ? [{ id: 'new', name: '+ Nieuw' }] : [])
        ]);
        if (opdrachtgevers.length > 0) {
          const opdrachtgever = selectedOption['instellingen'];
          if (opdrachtgever) {
            navigate(`/instellingen/${opdrachtgever}`);
          } else {
            navigate(`/instellingen/${opdrachtgevers[0].opdrachtgever_id}`);
          }
        }
      }
    }
  }, [res, opdrachtgevers]);

  return <OpdrachtgeverPage></OpdrachtgeverPage>;
};
