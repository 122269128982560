import React, { useEffect, useState } from 'react';
import { Bouwdeel, Installation, IdRegistration } from '../../../common/types';

import './DOIComponent.css';
import { FiRefreshCcw } from 'react-icons/fi';

interface DOIProps {
  curRegistration: IdRegistration;
  registrations: IdRegistration[];
  updateRegistration: (name: string, value: string | number) => void;
}

export const DOIComponent = ({ curRegistration, registrations, updateRegistration }: DOIProps) => {
  const [localDOI, setLocalDOI] = useState('');
  const [prefixDOI, setPrefixDOI] = useState('');
  const [bouwdeel, setBouwdeel] = useState<Bouwdeel | undefined>(curRegistration.bouwdeel || curRegistration.project?.bouwdeel);

  useEffect(() => {
    setPrefixDOI(generatePrefix(curRegistration));
    setLocalDOI('');
  }, [curRegistration.project, curRegistration.bouwdeel, curRegistration.floor, curRegistration.installation]);

  useEffect(() => {
    if(prefixDOI !== '' && !String(curRegistration.doi).startsWith(prefixDOI)) {
      updateRegistration('doi', -1);
    }
  }, [prefixDOI]);

  useEffect(() => {
    const newDOI = Number(prefixDOI + localDOI);
    if (isCorrect(newDOI)) {
      updateRegistration('doi', newDOI);
    } else if (localDOI !== '') {
      updateRegistration('doi', -1);
    }
  }, [localDOI, updateRegistration]);

  useEffect(() => {
    setBouwdeel(curRegistration.bouwdeel || curRegistration.project?.bouwdeel);
  }, [curRegistration.bouwdeel, curRegistration.project]);

  const updateLocalDOI = (value: string) => {
    if (!isNaN(+value)) {
      setLocalDOI(value);
    }
  };

  const isCorrect = (value: number) => {
    const sameDOIReg = registrations.find(reg => reg.doi === value && reg.id !== curRegistration.id);
    return !sameDOIReg
      && value > 0
      && value <= 4194304
      && localDOI !== '';
  };

  const autoDOI = (bd: Bouwdeel) => {
    const doi = generateDOI(prefixDOI, bd, registrations)[0];
    if (doi) {
      setLocalDOI(String(doi).substring(4));
    } else {
      alert('Kon geen DOI nummer vinden');
    }
  };

  const getLocalDOIDisplay = () => {
    if (localDOI !== '') {
      return localDOI;
    }
    if (curRegistration.doi === -1) {
      return '';
    }
    return prefixDOI ? String(curRegistration.doi).substring(4) : String(curRegistration.doi);
  }

  return (
    <div className="property">
      <label>
        DOI
        {
          bouwdeel &&
          <FiRefreshCcw
            title="Autogenereer IP" 
            className="ip-generate update-icon" 
            onClick={() => autoDOI(bouwdeel)}
          />
        }
      </label>
      {prefixDOI && <span>{prefixDOI}</span>}
      <input
        className={(prefixDOI ? 'doi-input ' : '') + (curRegistration.doi === -1 ? 'error' : '')}
        data-testid="doi-input"
        type="number"
        name="doi"
        value={getLocalDOIDisplay()}
        onChange={event => updateLocalDOI(event.target.value)}
        placeholder="Uniek DOI nummer"
      />
    </div>
  );
};

export const generatePrefix = (curRegistration: IdRegistration) => {
  const prefix =
    (curRegistration.installation === Installation.NAREGELING ? '1' : '') +
    (curRegistration.installation === Installation.PRIMAIR ? '2' : '') +
    curRegistration.floor.replace('_', '2') +
    (curRegistration.bouwdeel?.doi_code || curRegistration.project?.bouwdeel?.doi_code);
  if (prefix.length === 4) {
    return prefix;
  }
  return '';
};

export const generateDOI = (prefix: string, bouwdeel: Bouwdeel, registrations: IdRegistration[], amount = 1) => {
  const result = [] as number[];

  const existingDOIs = [
    ...registrations
      .filter(reg => (reg.project?.bouwdeel_id === bouwdeel.id) || reg.bouwdeel?.id === bouwdeel.id)
      .map(reg => reg.doi),
  ];
  [...Array(1000).keys()]
    .map(val => Number(prefix + String(val).padStart(3, '0')))
    .some(val => {
      if (!existingDOIs.includes(val)) {
        result.push(val);
      }
      return result.length === amount;
    });
  return result;
};
